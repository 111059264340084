import React, { useState, useLayoutEffect } from 'react';
import { graphql } from 'gatsby';
import {useTranslation} from "gatsby-plugin-react-i18next";
import { secureForEach, secureForEachNodes } from '@utils/secure-operations';

import Layout from '@components/layout'
import SEO from '@components/seo'
import { Hero } from '@molecules/hero'
import GoToTop from '@atoms/go-to-top'
import useParsedHtml from '@hooks/parsed-html';
import LeftMenu from '@molecules/left-menu';
import moveToElement from "@utils/move-to-element";
import sanitizeUrl from '@utils/sanitize-url';


import './chapter-template.scss';

const COMPONENT_CLASS = 'chapterContent';
const SECTION_PREFIX = 'Section';
const SECTION_PREFIX_SEPARATOR = ' | ';
const omitPrefixFor = [
  'Unpack this topic',
  'Décompressez ce sujet',
];

const ChapterTemplate = ({ data }) => {
  const { t } = useTranslation();
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { title, subtitle, description } = frontmatter;

  const parsedHtml = useParsedHtml(html);
  const [sidebarItems, setSidebarItems] = useState([]);
  const [movedToHash, setMovedToHash] = useState(false);

  const seoTitle = `${title}${description ? ` - ${description}` : ''}`;
  const bodyContentRef = React.createRef();

  const onClickSidebarLink = (clickedItem) => {
    if(!clickedItem || clickedItem.active) {
      return;
    }

    secureForEach(sidebarItems, item => item.active = false);

    clickedItem.active = true;
    const newItems = [...sidebarItems];
    setSidebarItems(newItems);
  };

  useLayoutEffect(()=> {
    const h2Items = bodyContentRef.current.querySelectorAll('h2');
    const items = [];
    let sectionTitlesCounter = 1;

    secureForEachNodes(h2Items, item => {
      const text = item.innerText;
      const active = false;
      const omitSectionPrefix = omitPrefixFor.includes(text);
      const sectionPrefix = !omitSectionPrefix ? `${t(SECTION_PREFIX)} ${sectionTitlesCounter} ${SECTION_PREFIX_SEPARATOR}` : '';
      const id = !omitSectionPrefix ? sanitizeUrl(`${t(SECTION_PREFIX)} ${sectionTitlesCounter++}`) : '';

      items.push({
        id,
        text: sectionPrefix + text,
        active,
      });
    });
    setSidebarItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedHtml]);

  useLayoutEffect(()=>{
    if (sidebarItems && sidebarItems.length) {
      moveToElement(movedToHash, setMovedToHash);
    }
  }, [sidebarItems, movedToHash]);

  /* Adding class to html to create assign smooth scroll by css */
  useLayoutEffect(() => {
    const currentClass = document.documentElement.className;
    document.documentElement.className += ` ${COMPONENT_CLASS}`;

    return () => document.documentElement.className = currentClass;
  }, []);

  return (
    <Layout className={COMPONENT_CLASS}>
      <SEO title={seoTitle} />
      <Hero
        title={title}
        subtitle={subtitle}
        description={description}
      />
      <GoToTop/>
      <section className={`container ${COMPONENT_CLASS}__container`}>
        <div className="row">
          <div className={`col-lg-3 ${COMPONENT_CLASS}__sidebar`}>
            <div className="sidebar__items sticky-top">
              <LeftMenu title={t('In this Chapter')} items={sidebarItems} onItemClick={(item)=> onClickSidebarLink(item)} scroll={true} />
            </div>
          </div>
          <div className="col-lg-9">
            <div
              className={`${COMPONENT_CLASS}__body`}
              ref={bodyContentRef}
              dangerouslySetInnerHTML={{ __html: parsedHtml }}
              >
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        subtitle
        title
        description
      }
    }
  }
`

export default ChapterTemplate;
