import { useState, useEffect } from 'react';
import sanitizeUrl from '@utils/sanitize-url';
import { useI18next } from 'gatsby-plugin-react-i18next'
import { secureForEachNodes } from '@utils/secure-operations';

const PARSED_CLASS = 'html__parsed';

const SPECIAL_CLASSES = {
  'top-tips': 'tips',
  'conseils-pratiques': 'tips',
};

const elementWithSuffix = [
    'nutrition'
];

const makeIdForElements = (elements) => {
  if (!elements || !elements.length) {
    return;
  }

  secureForEachNodes(elements, (item, index) => {
    const text = item.innerText;
    const id = sanitizeUrl(text);
    const specialClass = SPECIAL_CLASSES[id];
    const isH4 = item.tagName === 'H4';
    const idSuffix = isH4 ? `-${index + 1}` : '';
    const suffix2 = elementWithSuffix.includes(id) ? `-${index + 1}` : '';
    item.setAttribute('id', id + idSuffix + suffix2);

    if (specialClass) {
      item.classList.add(specialClass);
    }
  });

  return elements;
};

const makeClassBasedOnText = (text, words= 4) => {
  if (text) {
    const SEPARATOR = '-';
    const textParts = text.split(' ');
    const parts = textParts.slice(0, words);
    return sanitizeUrl(parts.join(SEPARATOR));
  }

  return '';
};


const useParsedHtml = (defaultHtml) => {
    const [html, setHtml] = useState(defaultHtml);
    const { language, defaultLanguage } = useI18next();

    useEffect(() => {
      const shadowElement = document.createElement('div');
      shadowElement.innerHTML = html;

      /* Making ids for h2 and h3 elements */
      const headingItems = shadowElement.querySelectorAll('h2, h3, h4');
      makeIdForElements(headingItems);

      /* Making ids for sections */
      const sections = shadowElement.querySelectorAll('section');
      secureForEachNodes(sections, section => {
        const heading = section.querySelector('h3') || section.querySelector('h2');
        if (heading) {
            const text = heading.innerText;
            const id = sanitizeUrl(text);
            section.setAttribute('data-id', id);
            section.setAttribute('id', `section-${id}`);
        }
      });

      /* Classes for special headings */
      const otherHeadings = shadowElement.querySelectorAll('h4');
      secureForEachNodes(otherHeadings, item => {
        const text = item.innerText;
        const id = sanitizeUrl(text);
        const specialClass = SPECIAL_CLASSES[id];

        if (specialClass) {
          item.classList.add(specialClass);
        }
      });

      /* Make blockquotes markup */
      const blockquoteImagesNodes = shadowElement.querySelectorAll('blockquote');
      secureForEachNodes(blockquoteImagesNodes, blockquote => {
        const wasParsedBefore = blockquote.classList.contains(PARSED_CLASS);
        if (wasParsedBefore) {
          return;
        }

        const p = blockquote.querySelector('p');
        const a = blockquote.querySelector('a');
        const img = blockquote.querySelector('img');

        if (img && p) {
          blockquote.classList.add(PARSED_CLASS);
          blockquote.classList.add(makeClassBasedOnText(p.innerText));
          const blockContainer = document.createElement('div');
          const imageContainer = document.createElement('div');
          /* Create a new image to prevent a bug on newImage gatsby-remark-images plugin */
          const newImage = document.createElement('img');
          newImage.src = img.src;
          newImage.alt = img.alt;
          p.classList.add('quote');
          const auxSpan = document.createElement('span');
          auxSpan.classList.add('aux');
          p.appendChild(auxSpan)

          imageContainer.classList.add('image__container');
          const itemAlt = img.getAttribute('alt');
          img.removeAttribute('style');

          const span = document.createElement('span');
          span.classList.add('main-text');
          span.innerText = itemAlt;

          if (a) {
            a.setAttribute('title', itemAlt);
            imageContainer.appendChild(a);
            a.appendChild(span);
          } else {
            const imageSrc = img.getAttribute('src');
            if (imageSrc) {
              imageContainer.appendChild(newImage);
            } else {
              img.classList.add('d-none');
            }
            imageContainer.appendChild(span);
          }

          blockContainer.appendChild(p);

          blockquote.appendChild(blockContainer);
          blockContainer.appendChild(imageContainer);
        }
      });

      setHtml(shadowElement.innerHTML);

    }, [html, language, defaultLanguage]);

    return html;
};

export default useParsedHtml;
